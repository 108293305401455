<div class="switchLang">
  <button class="p-button p-button-rounded switchLang__trigger" (click)="toggleOpen()"
          [ngClass]="{'hidden': tabOpened}">
    <img src="assets/icons/{{language}}.svg" alt="actual language">
  </button>
  <div class="switchLang__list" [ngClass]="{'hidden': !tabOpened}">
    <button class="p-button p-button-rounded" (click)="setLanguage('pl')">
      <img src="assets/icons/pl.svg" alt="choose language">
    </button>
    <button class="p-button p-button-rounded" (click)="setLanguage('lit')">
      <img src="assets/icons/lit.svg" alt="choose language">
    </button>
    <button class="p-button p-button-rounded" (click)="setLanguage('eng')">
      <img src="assets/icons/eng.svg" alt="choose language">
    </button>
    <button class="p-button p-button-rounded" (click)="setLanguage('de')">
      <img src="assets/icons/de.svg" alt="choose language">
    </button>
  </div>
</div>
