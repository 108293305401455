<div class="p-grid p-grid--noWrap">
  <div class="p-col-fixed p-p-0">
    <app-side-nav></app-side-nav>
  </div>
  <div class="p-col contentContainer background--grey">
    <router-outlet></router-outlet>
  </div>
</div>



