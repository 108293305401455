<ng-container *transloco="let t; read: 'users'">
  <div class="badge"
       [ngStyle]="{'color': color, 'background-color': hexToRgbA(color)}">
    <p-checkbox
      [label]="label"
      [(ngModel)]="selected"
      [disabled]="disabled"
      (onChange)="handleChangeEvent($event)"
      binary="true"
    ></p-checkbox>
  </div>
</ng-container>
