import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '../../api.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  usersOpened = false;
  syncMessage: Message[];

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private transloco: TranslocoService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {}

  toggleOpenUsers(): void {
    this.usersOpened = !this.usersOpened;
  }

  closeTabs(): void {
    this.usersOpened = false;
  }

  logout(): void {
    this.router.navigate(['/auth']);
    localStorage.removeItem('authToken');
  }

  handleProductsSync(): void {
    this.syncProducts();
  }

  syncProducts(): void {
    this.confirmationService.confirm({
      message: this.transloco.translate('syncProducts.message'),
      accept: () => {
        this.confirmationService.confirm({
          message: this.transloco.translate('syncProducts.pleaseWait'),
          key: 'waitDialog',
          acceptVisible: false,
          rejectVisible: false,
          blockScroll: true
        });

        this.apiService
          .getSapProducts()
          .pipe(take(1))
          .subscribe(
            () => {
              this.confirmationService.close();
              this.syncMessage = [
                {
                  summary: 'HTTP Request',
                  severity: 'success',
                  detail: 'success'
                }
              ];
            },
            (err: HttpErrorResponse) => {
              this.confirmationService.close();
              this.syncMessage = [
                {
                  summary: 'HTTP Error',
                  severity: 'error',
                  detail: err.message
                }
              ];
            },
            () => {
              setTimeout(() => {
                this.syncMessage = [];
              }, 5000);
            }
          );
      },
      acceptLabel: this.transloco.translate('syncProducts.yes'),
      rejectLabel: this.transloco.translate('syncProducts.no')
    });
  }
}
