<ng-container *transloco="let t; read: 'new-order'">
  <app-header [title]="title"></app-header>
  <p-confirmDialog [style]="{width: '40vw'}" styleClass="cDial" [baseZIndex]="10000"></p-confirmDialog>
  <p-messages [value]="msgs"></p-messages>

  <div class="orderingFormWrapper">
    <ng-container *ngFor="let form of modelGroups">
      <app-delta-form [models]="form.formModels"></app-delta-form>
    </ng-container>

    <div class="orderingFormButtons">
      <button type="button" (click)="confirm1()" pButton icon="pi pi-check" label="Confirm"></button>
      <button type="button" (click)="confirm2()" pButton icon="pi pi-times" label="Delete" class="p-ml-2"></button>
    </div>
  </div>
</ng-container>
