import { BrowserModule } from '@angular/platform-browser';
import { Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowComponent } from './pages/show/show.component';
import { SideNavComponent } from './pages/side-nav/side-nav.component';
import { RippleModule } from 'primeng/ripple';
import { OrderingFormModule } from './modules/ordering-form/ordering-form.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { SharedModule } from './pages/shared/shared.module';
import { AuthInterceptorService, ErrorInterceptor } from './pages/auth/auth-interceptor.service';
import { ApiService } from './api.service';
import { AuthGuardService } from './pages/auth/auth-guard.service';
import { DeliveryParamsComponent } from './pages/delivery-params/delivery-params.component';
import { ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ShowComponent,
    SideNavComponent,
    DeliveryParamsComponent
  ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ButtonModule,
		RippleModule,
		OrderingFormModule,
		HttpClientModule,
		TranslocoRootModule,
		SharedModule,
		ReactiveFormsModule
	],
  bootstrap: [AppComponent],
  providers: [
    ApiService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 }
  ]
})
export class AppModule {
}
