import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DeltaFormComponent } from './delta-form/delta-form.component';
import { OthersFormComponent } from './others-form/others-form.component';
import { OrderingFormMainComponent } from './ordering-form-main.component';


const routes: Routes = [
  { path: '', component: OrderingFormMainComponent, children: [
      { path: 'delta', component: OrderingFormMainComponent },
      { path: 'others', component: OrderingFormMainComponent },
      { path: '', pathMatch: 'full', redirectTo: 'delta'},
      { path: '**', redirectTo: 'delta'}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderingFormRoutingModule {}
