import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowComponent } from './pages/show/show.component';
import { AuthGuardService } from './pages/auth/auth-guard.service';
import { DeliveryParamsComponent } from './pages/delivery-params/delivery-params.component';

const routes: Routes = [
  {
    path: 'show', component: ShowComponent, canActivate: [AuthGuardService], children: [
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'changePwd',
        loadChildren: () => import('./pages/pwd-change/pwd-change.module').then(m => m.PwdChangeModule)
      },
      {
        path: 'deliveryParams',
        component: DeliveryParamsComponent
      }
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: '/show/users'},
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: '**', redirectTo: '/show/users'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
