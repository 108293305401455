import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoginForm } from './pages/auth/auth.model';
import { UserData } from './interfaces/user.interface';
import { PageSettings } from './pages/users/models/page-settings.interface';
import { Users } from './pages/users/models/users-interface';
import { User, UserWithPassword } from './pages/users/models/user-interface';
import { NewPassInterface } from './interfaces/new-pass.interface';
import { DeliveryDelayInterface } from './interfaces/delivery-delay.interface';
import { timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiBase = environment.apiBase;

  constructor(private http: HttpClient) {}

  login(payload: LoginForm): Observable<UserData> {
    return this.http.post<UserData>(this.apiBase + '/auth/admin/logIn', {
      ...payload
    });
  }

  forgotPass(payload: { email: string }): Observable<void> {
    return this.http.post<void>(
      `${this.apiBase}/auth/admin/forgotPassword`,
      payload
    );
  }

  getUsers(pageSettings: PageSettings): Observable<Users> {
    let params: HttpParams;

    if (pageSettings.sortValue && pageSettings.sortField) {
      params = new HttpParams({
        fromObject: {
          skip: (pageSettings.skip * pageSettings.limit).toString(),
          limit: pageSettings.limit.toString(),
          sortField: pageSettings.sortField,
          sortValue: pageSettings.sortValue
        }
      });
    } else {
      params = new HttpParams({
        fromObject: {
          skip: (pageSettings.skip * pageSettings.limit).toString(),
          limit: pageSettings.limit.toString()
        }
      });
    }

    return this.http.get<Users>(`${this.apiBase}/user/managers`, { params });
  }

  fetchClients(): Observable<void> {
    return this.http.get<void>(`${this.apiBase}/user/downloadManagersFromSAP`);
  }

  changeUserPermission(permissionDto): Observable<void> {
    return this.http.put<void>(
      `${this.apiBase}/user/updateUserPermission`,
      permissionDto
    );
  }

  activateClient(user: User): Observable<User> {
    return this.http.put<User>(
      this.apiBase + '/user/activate/' + user.id,
      user
    );
  }

  getSyncStatus(): Observable<any> {
    return this.http.get(`${this.apiBase}/products/getSyncStatus`);
  }

  getSapProducts(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiBase}/products/syncAll`, {
      headers: new HttpHeaders({ timeout: `${600000}` })
    });
  }

  changeAdminPassword(passwordDto: NewPassInterface): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiBase}/auth/admin/changePassword`,
      passwordDto
    );
  }

  changeManagerPassword(newPass: NewPassInterface): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.apiBase}/auth/manager/changePassword`,
      newPass
    );
  }

  createNewPassword(user: User): Observable<UserWithPassword> {
    return this.http.post<UserWithPassword>(
      `${this.apiBase}/auth/manager/createPassword`,
      user
    );
  }

  getDeliveryDelay(): Observable<DeliveryDelayInterface> {
    return this.http.get<DeliveryDelayInterface>(
      `${this.apiBase}/settings/deliveryDelay`
    );
  }

  setDeliveryDelay(
    data: DeliveryDelayInterface
  ): Observable<DeliveryDelayInterface> {
    return this.http.post<DeliveryDelayInterface>(
      `${this.apiBase}/settings/deliveryDelay`,
      data
    );
  }

  createAdminAccount(adminDto): Observable<any> {
    return this.http.post(`${this.apiBase}/user/createAdmin`, adminDto);
  }
}
