import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-picto-button, [pictoButton]',
  templateUrl: './picto-button.component.html',
  styleUrls: ['./picto-button.component.scss']
})
export class PictoButtonComponent implements OnInit {
  @Input() iconSrc: string;
  @Input() disabled = false;
  @Output() clickBtn: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(): void {
    if (!this.disabled) {
      this.clickBtn.emit();
    }
  }
}
