<div class="deltaForm">
  <ng-container *ngFor="let model of models; index as i">
    <!-- colors -->
    <table class="deltaForm__table deltaForm__table__colors deltaForm__table--inline" *ngIf="i === 0">
      <tr *ngFor="let color of getColors(model)">
        <th>{{ color.code }}</th>
        <th>{{ color.name }}</th>
      </tr>
    </table>

    <!-- models -->
    <table class="deltaForm__table deltaForm__table--inline"
           [ngClass]="model.isFrame? 'deltaForm__table__frame': 'deltaForm__table__noFrame'"
    >
      <tr class="deltaForm__table__thName">
        <th [colSpan]="getModelColumns(model)">{{ model.name }}</th>
      </tr>

      <ng-container *ngIf="model.types else simpleTable">
      <tr>
        <ng-container *ngFor="let type of model.types">
          <th [colSpan]="type.dimensions.length">
            <div class="deltaForm__table__thFlex">
              <span class="deltaForm__table__thFlex__text">{{ type.name }}</span>
              <img src="assets/icons/model-preview-icon.svg"
                   alt="preview"
                   class="deltaForm__table__thFlex__img"
              />
            </div>
          </th>
        </ng-container>
      </tr>

      <tr>
        <ng-container *ngFor="let type of model.types">
          <th *ngFor="let dimension of type.dimensions">{{ dimension.name }}</th>
        </ng-container>
      </tr>

      <tr *ngFor="let color of getColors(model)">
        <ng-container *ngFor="let type of model.types">
          <td *ngFor="let dimension of type.dimensions"
              [ngClass]="{'deltaForm__emptyData': !getColorOptions(dimension, color)}"
          >
            <!-- quantity dropdown -->
            <p-dropdown
              *ngIf="getColorOptions(dimension, color)"
              [options]="options"
            ></p-dropdown>
          </td>
        </ng-container>
      </tr>
      </ng-container>

      <ng-template #simpleTable>
        <tr>
          <th *ngFor="let dimension of model.dimensions">{{ dimension.name }}</th>
        </tr>

        <tr *ngFor="let color of getColors(model)">
          <td *ngFor="let dimension of model.dimensions"
              [ngClass]="{'deltaForm__emptyData': !getColorOptions(dimension, color)}"
          >
            <!-- quantity dropdown -->
            <p-dropdown
              *ngIf="getColorOptions(dimension, color)"
              [options]="options"
            ></p-dropdown>
          </td>
        </tr>
      </ng-template>
    </table>
  </ng-container>
</div>
