import {Component, OnInit} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})
export class SwitchLangComponent implements OnInit {
  tabOpened = false;
  language: string;

  constructor(private translocoService: TranslocoService) {
  }

  ngOnInit() {
    this.language = this.translocoService.getActiveLang();
  }

  public setLanguage = (language) => {
    this.language = language;
    this.translocoService.setActiveLang(language);
    localStorage.setItem('lang', language);
    this.translocoService.load(language).subscribe();
    this.toggleOpen();
  };

  toggleOpen() {
    this.tabOpened = !this.tabOpened;
  }
}
