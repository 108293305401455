<ng-container *transloco="let t; read: 'nav'">
  <div class="p-grid sideNav">
    <div class="p-col-fixed p-col-nogutter sideNav__bottomMenu">
<!--      <button class="p-button p-button-rounded userSettings"><img src="assets/icons/user.svg"></button>-->
      <button class="p-button p-button-rounded logOut" (click)="logout()" routerLink="../auth"><img
        src="assets/icons/no-way.svg"></button>
    </div>
    <div class="p-col-fixed p-col-nogutter">
      <div class="sideNav__menu p-d-flex p-jc-center p-col-fixed p-col-nogutter">
        <div class="p-grid p-nogutter p-dir-col p-d-flex p-mt-3">
          <button pButton pRipple type="button" label="{{ t('users')}}"
                  class="p-button-raised p-button-text p-my-2 tabButton labelWhite"
                  routerLink="users">
            <img src="assets/icons/user-white.svg">
          </button>

          <button pButton pRipple type="button" label="{{ t('syncProducts')}}"
                  class="p-button-raised p-button-text p-my-2 tabButton labelWhite"
                  (click)="handleProductsSync()">
            <img src="assets/icons/settings.svg">
          </button>

          <button pButton pRipple type="button" label="{{ t('changePassword')}}"
                  class="p-button-raised p-button-text p-my-2 tabButton labelWhite"
                  routerLink="changePwd">
            <img src="assets/icons/settings.svg">
          </button>

          <button pButton pRipple type="button" label="{{ t('deliveryParams')}}"
                  class="p-button-raised p-button-text p-my-2 tabButton labelWhite"
                  routerLink="deliveryParams">
            <img src="assets/icons/settings.svg">
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<p-messages [(value)]="syncMessage"></p-messages>
<p-confirmDialog class="noCloseMark" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog class="noCloseMark" key="waitDialog"></p-confirmDialog>


