<div class="p-field capekInput" [ngClass]="{'capekInput--white': white}">
  <label [for]="id" class="p-d-block" *ngIf="label">
    <span *ngIf="control.invalid && control.errors['required']">*</span>
    {{label}}
  </label>
  <img *ngIf="iconSrc" class="capekInput__icon" [src]="iconSrc" alt="icon">
  <input
    [id]="id"
    [type]="type"
    #inputElement
    [value]="control ? control.value : value"
    [ngClass]="{'capekInput__input--withIcon': iconSrc}"
    aria-describedby="input1-help"
    [placeholder]="placeholder"
    pInputText
    class="p-inputtext-lg capekInput__input"
    [disabled]="disabled"
    (change)="onChange($event)"
  />
  <small id="input1-help" class="p-d-block capekInput__errorMsg"
         *ngIf="control && control.invalid && (control.dirty || control.touched)"
  ><span *ngFor="let msg of errors">{{ msg }}</span></small>
</div>
