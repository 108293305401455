import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-delivery-params',
  templateUrl: './delivery-params.component.html',
  styleUrls: ['./delivery-params.component.scss']
})
export class DeliveryParamsComponent implements OnInit {
  pendingRequest = false;
  deliveryParamsForm: FormGroup;
  deliveryDelay: number;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.getDeliveryDelay();

    this.deliveryParamsForm = this.fb.group({
      deliveryDelay: [this.deliveryDelay, [Validators.required, RxwebValidators.numeric({
        acceptValue: NumericValueType.PositiveNumber,
        allowDecimal: false
      })]]
    });
  }

  getDeliveryDelay(): void {
    this.apiService.getDeliveryDelay().pipe(take(1)).subscribe((res) => {
      this.deliveryDelay = res.deliveryDelay;
      this.deliveryParamsForm.get('deliveryDelay').patchValue(res.deliveryDelay);
    }, (error: HttpErrorResponse) => {
      this.deliveryParamsForm.get('deliveryDelay').patchValue(0);
    });
  }

  setDeliveryDelay(): void {
    const body = {
      deliveryDelay: Number(this.deliveryParamsForm.value.deliveryDelay)
    };

    this.apiService.setDeliveryDelay(body)
      .pipe(take(1))
      .subscribe((res) => {
      console.log(res);
    });
  }
}
