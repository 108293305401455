import { Component, ElementRef, forwardRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  }]
})
export class InputComponent implements ControlValueAccessor {
  value = '';
  @ViewChild('inputElement') _inputElement: ElementRef;
  @Input() type = 'text';
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: AbstractControl;
  @Input() name: string;
  @Input() infoMsg: string;
  @Input() disabled = false;
  @Input() iconColor: string;
  @Input() iconSrc: string;
  @Input() white: boolean;
  errors: string[] = [];

  constructor(
    private translocoService: TranslocoService
  ) {
  }

  _onChange = (_: any): void => {
  }
  _onTouched = (): void => {
  }

  get inputElement(): ElementRef {
    return this._inputElement;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;

    this.getInfoMsg();
  }

  onChange(event: any): void {
    if (this.control && !this.control.valid && this.control.dirty) {
      this.resetValue(event);
      this.emitValue(event);
    } else {
      this.emitValue(event);
    }

    this.getInfoMsg();
  }

  resetValue(event: any): void {
    this.value = '';
    this.control.setValue('');
  }

  emitValue(event: any): void {
    this._onChange(event.target.value);
  }

  getInfoMsg(): void {
    if (this.control && this.control.errors) {
      Object.keys(this.control.errors).forEach(key => {
        const value = this.control.errors[key];
        if (value) {
          this.errors = [...this.translocoService.translate('forms.validationErrors.' + key)];
        }
      });
    }
  }
}
