import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoorModelGroup } from './models/door-model';
import { ConfirmationService, Message } from 'primeng';

@Component({
  selector: 'app-ordering-form-main',
  templateUrl: './ordering-form-main.component.html',
  styleUrls: ['./ordering-form-main.component.scss']
})
export class OrderingFormMainComponent implements OnInit {
  title = 'Nowe zamówienie';
  modelGroups: DoorModelGroup[];
  msgs: Message[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    // todo: request for model groups depended on activated route and render correct forms by passed data
    // using mocks until backend ready
    this.modelGroups = [
      {
        formModels: [
          {
            name: 'Skrzydło stratus DS68',
            types: [
              {
                name: 'Gładkie',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 12
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 12
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'T15',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: null
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'W16',
                dimensions: [
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'W3',
                dimensions: [
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              }
            ],
            isFrame: false
          },
          {
            name: 'Ościeżnica Stratuss',
            types: [
              {
                name: 'Stalowa P 200',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: null
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              }
            ],
            isFrame: true
          }
        ]
      },
      {
        formModels: [
          {
            name: 'T55 U',
            types: [
              {
                name: 'Gładkie',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'T15',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: null
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'W16',
                dimensions: [
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: 24
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              },
              {
                name: 'W3',
                dimensions: [
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '100E P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              }
            ],
            isFrame: false
          },
          {
            name: 'Ościeżnica T55',
            types: [
              {
                name: 'Stalowa P 200',
                dimensions: [
                  {
                    name: '80N L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '80N P',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  },
                  {
                    name: '90E L',
                    colors: [
                      {
                        code: 'DL 89',
                        name: 'Zł dąb',
                        quantity: 12
                      },
                      {
                        code: 'DL 30',
                        name: 'Nut',
                        quantity: 12
                      },
                      {
                        code: 'A45',
                        name: 'Biały',
                        quantity: null
                      },
                      {
                        code: 'N75',
                        name: 'Antracyt',
                        quantity: 24
                      }
                    ]
                  }
                ]
              }
            ],
            isFrame: true
          }
        ]
      },
      {
        formModels: [
          {
            name: 'Mars/uran (qwe+123)',
            dimensions: [
              {
                name: '80N L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '80N P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '100E L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '100E P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '80N L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '80N P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '100E L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '100E P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '80N L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '80N P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E L',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              },
              {
                name: '90E P',
                colors: [
                  {
                    code: 'DL 89',
                    name: 'Zł dąb',
                    quantity: 12
                  },
                  {
                    code: 'DL 30',
                    name: 'Nut',
                    quantity: 12
                  },
                  {
                    code: 'A45',
                    name: 'Biały',
                    quantity: null
                  },
                  {
                    code: 'N75',
                    name: 'Antracyt',
                    quantity: 24
                  }
                ]
              }
            ],
            isFrame: false
          }
        ]
      }
    ];
  }

  confirm1() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{severity: 'info', summary: 'Confirmed', detail: 'You have accepted'}];
      },
      reject: () => {
        this.msgs = [{severity: 'info', summary: 'Rejected', detail: 'You have rejected'}];
      }
    });
  }

  confirm2() {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.msgs = [{severity: 'info', summary: 'Confirmed', detail: 'Record deleted'}];
      },
      reject: () => {
        this.msgs = [{severity: 'info', summary: 'Rejected', detail: 'You have rejected'}];
      }
    });
  }
}
