import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SwitchLangComponent } from './switch-lang/switch-lang.component';
import { InputComponent } from './form/input/input.component';
import { FormsModule } from '@angular/forms';
import {
  CheckboxModule, DialogService, DynamicDialogConfig,
  DynamicDialogModule, DynamicDialogRef,
  InputTextModule,
  PaginatorModule,
  ProgressSpinnerModule,
  TableModule
} from 'primeng';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule, ConfirmDialogModule, MessagesModule } from 'primeng';
import { RippleModule } from 'primeng/ripple';
import { TranslocoModule } from '@ngneat/transloco';
import { AvatarModule } from 'ngx-avatar';
import { BadgeComponent } from './badge/badge.component';
import { PictoButtonComponent } from './picto-button/picto-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [HeaderComponent, SwitchLangComponent, InputComponent, BadgeComponent, PictoButtonComponent],
  exports: [
    HeaderComponent,
    SwitchLangComponent,
    InputComponent,
    DropdownModule,
    ButtonModule,
    MessagesModule,
    ConfirmDialogModule,
    TranslocoModule,
    TableModule,
    AvatarModule,
    BadgeComponent,
    PictoButtonComponent,
    PaginatorModule,
    AngularSvgIconModule,
    CheckboxModule,
    ProgressSpinnerModule,
    RippleModule,
    DynamicDialogModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    MessagesModule,
    ConfirmDialogModule,
    TranslocoModule,
    TableModule,
    AvatarModule,
    PaginatorModule,
    AngularSvgIconModule.forRoot(),
    CheckboxModule,
    ProgressSpinnerModule,
    RippleModule,
    DynamicDialogModule
  ],
  providers: [
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef
  ]
})
export class SharedModule {
}
