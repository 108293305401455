<ng-container *transloco="let t; read: 'deliveryParams'">
  <div class="deliveryParams">
    <app-header class="users__title" [title]="t('title')"></app-header>

    <div class="deliveryParams__form">
      <form [formGroup]="deliveryParamsForm" (ngSubmit)="setDeliveryDelay()">
        <div class="form-group p-grid p-dir-col">
          <app-input
            *ngIf="deliveryParamsForm.controls.deliveryDelay"
            [white]="false"
            [id]="'deliveryDelay'"
            [type]="'number'"
            [control]="deliveryParamsForm.controls.deliveryDelay"
            [placeholder]="t('deliveryDelayPlaceholder')"
            [label]="t('deliveryDelayLabel')"
            formControlName="deliveryDelay"
          ></app-input>
        </div>

        <button pButton pRipple type="submit" label="{{ t('save')}}"
                class="p-button-rounded p-text-uppercase addN*ew p-mt-4 p-mb-2"
                [disabled]="!deliveryParamsForm.valid || pendingRequest">
        </button>
      </form>
    </div>
  </div>
</ng-container>
