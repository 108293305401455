import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderingFormMainComponent } from './ordering-form-main.component';
import { OrderingFormRoutingModule } from './ordering-form-routing.module';
import { DeltaFormComponent } from './delta-form/delta-form.component';
import { OthersFormComponent } from './others-form/others-form.component';
import { SharedModule } from '../../pages/shared/shared.module';
import { ConfirmationService } from 'primeng';

@NgModule({
  declarations: [OrderingFormMainComponent, DeltaFormComponent, OthersFormComponent],
  imports: [
    CommonModule,
    OrderingFormRoutingModule,
    SharedModule
  ],
  providers: [
    ConfirmationService
  ]
})
export class OrderingFormModule { }
