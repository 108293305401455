import { Component, Input, OnInit } from '@angular/core';
import { DoorColor, DoorDimension, DoorModel } from '../models/door-model';


@Component({
  selector: 'app-delta-form',
  templateUrl: './delta-form.component.html',
  styleUrls: ['./delta-form.component.scss']
})
export class DeltaFormComponent implements OnInit {
  @Input() models: DoorModel[];
  options: any[];

  constructor() {
  }

  ngOnInit(): void {
    this.options = [
      {
        label: 12,
        value: 12
      },
      {
        label: 24,
        value: 24
      },
      {
        label: 48,
        value: 48
      },
      {
        label: 96,
        value: 96
      },
    ];
  }

  getColors(model: DoorModel): DoorColor[] {
    if (model.types) {
      return model.types[0].dimensions[0].colors;
    }

    if (model.dimensions) {
      return model.dimensions[0].colors;
    }

    return [];
  }

  getColorOptions(dimension: DoorDimension, color: DoorColor): number | null {
    return dimension.colors.find((dimensionColor) => dimensionColor.code === color.code).quantity;
  }
  //
  // getDropDownOptions(dimension: DoorDimension, color: DoorColor): any {
  //   return dimension.colors.find((dimensionColor) => dimensionColor.code === color.code).quantity.map((q) => {
  //     return {
  //       label: q,
  //       value: q
  //     };
  //   });
  // }

  getModelColumns(model: DoorModel): number {
    if (model.types) {
      let columns = 0;
      model.types.map((type) => {
        columns += type.dimensions.length;
      });
      return columns;
    }

    if (model.dimensions) {
      return model.dimensions.length;
    }
  }
}
