import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor() {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken: string = localStorage.getItem('authToken');
    if (authToken) {
      const modifiedReq = req.clone({
        // params: new HttpParams().set('Authorization', `Bearer ${authToken}`)
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          Accept: '*/*'
        }
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: any) => {},
        error => {
          if (error.status === 401) {
            localStorage.removeItem('auth');
            return this.router.navigateByUrl('/auth');
          }
          if (error.status === 403) {
            localStorage.removeItem('auth');
            return this.router.navigateByUrl('/auth');
          }
        },
      ),
    );
  }
}
