import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[appBadge]',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() color: string;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  hexToRgbA(hex): string {
    const RGB_COLOR_REGEX = /^#([A-Fa-f0-9]{3}){1,2}$/;

    let color;
    if (RGB_COLOR_REGEX.test(hex)){
      color = hex.substring(1).split('');
      if (color.length === 3){
        color = [color[0], color[0], color[1], color[1], color[2], color[2]];
      }
      color = '0x' + color.join('');
      // tslint:disable-next-line:no-bitwise
      return 'rgba(' + [ (color >> 16) & 255, ( color >> 8) & 255, color & 255].join(',') + ',0.1)';
    }
  }

  handleChangeEvent(event): void {
    const permissionState = {
      label: this.label,
      state: event.checked
    };

    this.changeEvent.emit(permissionState);
  }
}
